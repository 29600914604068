const local = {
  api_root: "https://w18nm4pfz4.execute-api.us-east-2.amazonaws.com/console/",
  google_analytics_ua: "75484836-1",
};

const alpha = {
  api_root: "https://api-alpha.clicksandhisses.com",
  google_analytics_ua: "75484836-1",
};

const stable = {
  api_root: "https://api-stable.clicksandhisses.com",
  google_analytics_ua: "75484836-2",
};

let config = null;

switch (process.env.REACT_APP_ENV.toLowerCase()) {
  case "local":
    config = local;
    break;
  case "alpha":
    config = alpha;
    break;
  case "stable":
    config = stable;
    break;
  default:
    alert("Invalid config!");
    break;
}

let configExport = {
  ...config,
};

export default configExport;
