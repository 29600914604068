import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Nav = () => {
  const [slideMenuOpen, setSlideMenuOpen] = useState(false);
  const location = useLocation();

  let navLabel = location.pathname.split("/")[1].replace(".html", "");

  return (
    <React.Fragment>
      <div className="nav-container">
        <NavLink exact to="/">
          <img
            src="https://cdn.surlydigital.com/cah/assets/branding/cah-header-logo.png"
            className="nav-logo"
            alt="Nav Logo"
          ></img>
        </NavLink>
        <div className="nav-links">
          <div className="nav-link">
            <NavLink to="/lyrics">Lyrics</NavLink>
          </div>
          <div className="nav-link">
            <NavLink to="/map">Map</NavLink>
          </div>
          <div className="nav-link">
            <NavLink to="/search">Search</NavLink>
          </div>
          <div className="nav-link">
            <NavLink to="/about">About</NavLink>
          </div>
        </div>
      </div>
      <div className="nav-container-mobile">
        <div
          className="btn-hamburger"
          onClick={(e) => setSlideMenuOpen(!slideMenuOpen)}
        >
          <FontAwesomeIcon icon={slideMenuOpen ? faTimes : faBars} />
        </div>
        <div className="nav-label">{navLabel ? navLabel : "Home"}</div>
      </div>
      <div
        className={`slide-menu-container ${
          slideMenuOpen ? "menu-open" : "menu-close"
        }`}
      >
        <div className="slide-menu-links-container">
          <h1 className="slide-menu-link">
            <NavLink
              exact
              to="/"
              className="override"
              onClick={(e) => setSlideMenuOpen(false)}
            >
              Home
            </NavLink>
          </h1>
          <h1 className="slide-menu-link">
            <NavLink
              exact
              to="/lyrics"
              className="override"
              onClick={(e) => setSlideMenuOpen(false)}
            >
              Lyrics
            </NavLink>
          </h1>
          <h1 className="slide-menu-link">
            <NavLink
              to="/map"
              className="override"
              onClick={(e) => setSlideMenuOpen(false)}
            >
              Map
            </NavLink>
          </h1>
          <h1 className="slide-menu-link">
            <NavLink
              to="/search"
              className="override"
              onClick={(e) => setSlideMenuOpen(false)}
            >
              Search
            </NavLink>
          </h1>
          <h1 className="slide-menu-link">
            <NavLink
              to="/about"
              className="override"
              onClick={(e) => setSlideMenuOpen(false)}
            >
              About
            </NavLink>
          </h1>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nav;
